import React from 'react';
import { cookieContent } from '../../content';
import LegalHeader from '../../components/LegalHeader';
import LegalBody from '../../components/LegalBody';
import DocumentHead from '../../components/DocumentHead';
// @ts-ignore
import htmlContent from '../../content/cookies.html';

function Cookies() {
  const { header, metadata } = cookieContent;
  return (
    <div data-testid="cookies-page-container" className="md-900px:pt-9 pt-7">
      <DocumentHead content={metadata} />
      <div className="md:hidden">
        <LegalHeader header={header.mobile} />
      </div>
      <div className="hidden md:block">
        <LegalHeader header={header.desktop} />
      </div>
      <LegalBody
        htmlContent={htmlContent}
        buttonStyle="flex justify-center space-x-3 xl:space-x-4 my-7 md:my-10"
      />
    </div>
  );
}

export default Cookies;
