import React from 'react';

type LegalHeaderPropsType = {
  header: string;
};

function LegalHeader({ header }: LegalHeaderPropsType) {
  return (
    <header
      id="header"
      className="flex px-6 md:px-64px lg:px-68px md:py-10 py-7 bg-background text-24px md:text-36px font-extrabold mb-7 md:mb-10"
    >
      {header}
    </header>
  );
}

export default LegalHeader;
